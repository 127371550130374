import React from 'react';
import { Link } from 'react-router-dom';

const Aboutus = () => {
  return (
    <div>
      This is About us Page
      <br/>
      <Link to="/">Home</Link>
    </div>
  );
};

export default Aboutus;